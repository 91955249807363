//Libraries
import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

//Components

//Assets
import Tick from '../../assets/tick.svg';

const DifficultyCard = ({
  handler,
  selectedDifficultyLevel,
  text,
  body,
  cardDifficultyLevel,
  difficultyImage,
}) => {
  let isSelected = cardDifficultyLevel === selectedDifficultyLevel;

  return (
    <label>
      <Box display='none'>
        <input
          type='radio'
          name='level'
          value={cardDifficultyLevel}
          onClick={handler}
        />
      </Box>
      <Box>
        <Box width='210px' height='370px'>
          <Paper
            style={{
              borderRadius: '4px',
              backgroundColor: isSelected ? '#201B25' : '#383838',
              width: '100%',
              height: '90%',
              border: isSelected ? '3px solid #BA85FA' : 'none',
              boxSizing: 'border-box',
            }}
            elevation={8}
          >
            <Box p={isSelected ? 2.65 : 3}>
              <Box display='flex' justifyContent='center' position='relative'>
                <Typography variant='subtitle1' display='inline'>
                  {text}
                </Typography>
                <Box display='inline' position='absolute' left={156} bottom={8}>
                  {isSelected ? <img src={Tick} alt='selected' /> : ''}
                </Box>
              </Box>

              <Box display='flex' justifyContent='center' mt={2} mb={4}>
                <img
                  src={difficultyImage}
                  alt='medium'
                  style={{ width: '120px' }}
                />
              </Box>
              <Typography variant='body2'>{body}</Typography>
            </Box>
          </Paper>
        </Box>
      </Box>
    </label>
  );
};

export default DifficultyCard;
