//Libraries
import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';

function IntroductionAudio() {
  const [buttonState, setButtonState] = useState(true);
  const selectedDifficultyLevel = useSelector(
    (state) => state.appData.selectedDifficultyLevel
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (selectedDifficultyLevel === 'null') {
      navigate('/select-difficulty');
    }
  }, [selectedDifficultyLevel]);

  function audioComplete() {
    setButtonState(false);
  }

  const audio = useRef();

  return (
    <Box textAlign='center' pt={8}>
      <Typography variant='h1' px={4}>
        SUPER! HÖRT EUCH JETZT DAS EINFÜHRUNGS-AUDIO AN
      </Typography>
      <Box mt={18} mb={8}>
        {selectedDifficultyLevel !== 'null' && (
          <ReactAudioPlayer
            src={`/audios/${selectedDifficultyLevel}.mp3`}
            controls
            controlsList='nodownload'
            onEnded={audioComplete}
            ref={(element) => {
              if (element !== null) {
                audio.current = element.audioEl;
              }
            }}
          />
        )}
      </Box>
      <Box pt={8} pb={8}>
        <Typography display='inline' variant='subtitle2'>
          Kein Ton? Klicke{' '}
        </Typography>
        <Typography
          display='inline'
          color='#087FD4'
          onClick={() => audio.current.current.play()}
        >
          hier
        </Typography>
      </Box>
      <Box mb={8}>
        <Button
          to='/select-question'
          variant='contained'
          disabled={buttonState}
          component={Link}
        >
          ZUM POLIZEI-SERVER
        </Button>
      </Box>
    </Box>
  );
}

export default IntroductionAudio;
