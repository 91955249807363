import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  setDoc,
  query,
  where,
  orderBy,
} from 'firebase/firestore';

export const createDoc = async (ref, newDoc) => {
  await addDoc(ref, newDoc);
};

export const createDocWithId = async (db, collection, id, newDoc) => {
  await setDoc(doc(db, collection, id), newDoc);
};










export const getUserDataWithPhoneNumber = async (
  db,
  phoneNumber,
  difficultySelected
) => {
  const q = query(
    collection(db, 'userData'),
    where('phoneNumber', '==', parseInt(phoneNumber))
  );

  let result;

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    result = { ...doc.data(), id: doc.id };
  });



  // if the user doesn't exist, set up the datastructures for userData
  if (!result) {


    // get number of stages saved in firestore
    // charges one document read per counted item so be careful!
    let numberOfStages = 4;
    const q_2 = query(collection(db, 'stagesData'));
    const querySnapshot_2 = await getDocs(q_2);
    numberOfStages = querySnapshot_2.size;


    let stageObject = {};

    // that is ugly. redo with .map() or so
    for (let i = 1; i <= numberOfStages; i++) {
      stageObject["stage"+i] = [] ;
    }

    await setDoc(doc(db, 'userData', phoneNumber), {
      phoneNumber: parseInt(phoneNumber),
      difficultySelected,
      completedGame: false,
      solvedStages: [],
      finishedCalls: 0,
      attemptedCalls: 0,
      givenHints: [],
      displayedWrongAnswerHints: stageObject
    });

    await setDoc(doc(db, 'wrongAnswers', phoneNumber), {});


    await setDoc(doc(db, 'timeStamps', phoneNumber), {
      startedGame: Math.floor(Date.now() / 1000),
    });
    // await addDoc(collection(db, 'userData'), {
    //   phoneNumber: parseInt(phoneNumber),
    //   difficultySelected,
    //   completedGame: false,
    //   solvedStages: [],
    //   madeCalls: 0,
    //   givenHints: [],
    // });

    const q = query(
      collection(db, 'userData'),
      where('phoneNumber', '==', parseInt(phoneNumber))
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      result = { ...doc.data(), id: doc.id };
    });
  }

  return result;
};









export const getData = async (ref) => {
  const data = await getDocs(ref);
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

export const getStagesData = async (ref) => {
  const q = query(ref, orderBy('stageNumber', 'asc'));
  const data = await getDocs(q);
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

export const updateDocData = async (db, id, data) => {
  const userDoc = doc(db, 'userData', id);
  await updateDoc(userDoc, data);
};

export const updateTimeStamp = async (db, id, data) => {
  const timeStamp = doc(db, 'timeStamps', id);
  await updateDoc(timeStamp, data);

};




export const addWrongAnswerEntry = async (db, id, data) => {
  
  // If you want to add the wrong answers directly into the userData Collection:
  //const userDoc = doc(db, 'userData', id);
  //console.log(userDoc);
  //await updateDoc(userDoc, data);


  const wrongAnswerDoc = doc(db, 'wrongAnswers', id);
  await updateDoc(wrongAnswerDoc, data);
};







export const addRating = async (db, userPhoneNumber, value) => {
  const ref = collection(db, 'ratings');

  const q = query(
    collection(db, 'ratings'),
    where('userPhoneNumber', '==', parseInt(userPhoneNumber))
  );

  let result;

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    result = { ...doc.data() };
  });

  if (!result) {
    createDoc(ref, {
      userPhoneNumber: userPhoneNumber,
      rating: value,
    });
  }
};

export const addReview = async (db, userPhoneNumber, review) => {
  const ref = collection(db, 'reviews');

  const q = query(
    collection(db, 'reviews'),
    where('userPhoneNumber', '==', parseInt(userPhoneNumber))
  );

  let result;

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    result = { ...doc.data() };
  });

  if (!result) {
    createDoc(ref, {
      userPhoneNumber: userPhoneNumber,
      review: review,
    });
  }
};

export const findRating = async (db, userPhoneNumber) => {
  const q = query(
    collection(db, 'ratings'),
    where('userPhoneNumber', '==', parseInt(userPhoneNumber))
  );

  let result;

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    result = { ...doc.data() };
  });

  return result;
};

export const findReview = async (db, userPhoneNumber) => {
  const q = query(
    collection(db, 'reviews'),
    where('userPhoneNumber', '==', parseInt(userPhoneNumber))
  );

  let result;

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    result = { ...doc.data() };
  });

  return result;
};

// Use this for your collection and send to functions
// import { db } from '../../utils/firebaseConfig';
// const stagesDataCollectionRef = collection(db, 'stagesData');

// Use this to inject data into firestore
// useEffect(() => {
//   stagesData.forEach((i) => {
//     createStage(i);
//   });
// });
