import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedDifficultyLevel: 'null',
  questionSelected: 'null',
  selectedPersonsList: {},
  selectedStageNumber: 'null',
};

export const appDataSlice = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    setDifficultyLevel: (state, action) => {
      state.selectedDifficultyLevel = action.payload;
    },
    setSelectedPersonsList: (state, action) => {
      if (action.payload.isSingle) {
        state.selectedPersonsList = action.payload;
      } else {
        state.selectedPersonsList[action.payload.partialQuestionNumber] =
          action.payload.selectedPersonName;
      }
      if (action.payload === 'flush') {
        state.selectedPersonsList = {};
      }
    },
    setSelectedStageNumber: (state, action) => {
      state.selectedStageNumber = action.payload;
    },
  },
});

export const {
  setDifficultyLevel,
  setSelectedPersonsList,
  setSelectedStageNumber,
} = appDataSlice.actions;

export default appDataSlice.reducer;
