//Libraries
import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

//Assets
import Tick from '../../assets/tick.svg';

export default function PersonCard({
  name,
  selectedPersonName,
  personNameHandler,
}) {
  let isSelected = name === selectedPersonName ? true : false;

  return (
    <label>
      <Box display='none'>
        <input
          type='radio'
          name='selectedPerson'
          value={name}
          onClick={personNameHandler}
        />
      </Box>
      <Box
        border={isSelected ? '2px solid #BA85FA' : '2px solid #3F3F3F'}
        borderRadius={1}
        width='150px'
        height='220px'
        textAlign='center'
      >
        <Paper
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: isSelected ? '#201B25' : '',
          }}
          elevation={10}
        >
          <Box p={2.5} position='relative'>
            <Box display='inline' position='absolute' left={120} top={10}>
              {isSelected ? <img src={Tick} alt='selected' /> : ''}
            </Box>
            <img
              src={`/characters/${name}.jpg`}
              alt={name}
              width='110px'
              height='110px'
            />
            <Box mt={2}>
              <Typography variant='subtitle1' style={{ fontSize: '16px' }}>
                {name}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </label>
  );
}
