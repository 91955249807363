//Libraries
import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  TextField,
  Box,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { phone } from "phone";

//Components
import CustomModal from "../../components/CustomModal/CustomModal";

//Assets
import Phone from "../../assets/phone.png";
import germany from "../../assets/+49.svg";
import austria from "../../assets/+43.png";
import switzerland from "../../assets/+41.png";
import luxemburg from "../../assets/+352.png";

//Utils
import {
  setCurrentUser,
  setPhoneNumber,
} from "../../features/userData/userDataSlice";
import { getUserDataWithPhoneNumber } from "../../utils/firestoreFunctions";
import { db } from "../../utils/firebaseConfig";

export default function PhoneNumber() {
  const [open, setOpen] = useState(false);
  const [number, setNumber] = useState("");
  const [fullNumber, setFullNumber] = useState("");
  const [isValid, setIsValid] = useState("");
  const [countryCode, setCountryCode] = useState(49);
  const dispatch = useDispatch();

  const selectedDifficultyLevel = useSelector(
    (state) => state.appData.selectedDifficultyLevel
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (selectedDifficultyLevel === "null") {
      navigate("/select-difficulty");
    }
  }, [selectedDifficultyLevel]);

  const handleOpen = () => {
    dispatch(setPhoneNumber(fullNumber.slice(1)));
    fetch(
      `https://europe-west3-cc-01-kunstfehler-de.cloudfunctions.net/startGameTwilio?phoneNumber=${fullNumber.slice(
        1
      )}`,
      { mode: "no-cors" }
    );
    const getData = async () => {
      let temp = await getUserDataWithPhoneNumber(
        db,
        fullNumber.slice(1),
        selectedDifficultyLevel
      );
      dispatch(setCurrentUser(temp));
    };
    getData();
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const numberChangeHandler = (e) => {
    setNumber(e.target.value);
    if (/^\d+$/.test(e.target.value)) {
      let obj = phone("+" + String(countryCode) + e.target.value);
      setFullNumber(obj.phoneNumber);
      if (obj.isValid) {
        setIsValid("true");
      } else {
        setIsValid("false");
      }
    } else {
      setIsValid("false");
    }
  };

  return (
    <Box textAlign="center" pt={8}>
      <Typography variant="h1">GIB DEINE NUMMER EIN</Typography>
      <Box mt={8}>
        <img src={Phone} alt="phone" width="150px" />
      </Box>
      <Box mt={8} mb={12} paddingX={6}>
        <Typography variant="body2">
          Wie kann der Kommissar dich erreichen? Er hat Tipps für dich!
        </Typography>
      </Box>
      <Box display="inline-block" mr={1}>
        <Select
          height="32px"
          id="country"
          value={countryCode}
          onChange={(e) => {
            setCountryCode(e.target.value);
            setNumber("");
            setIsValid("false");
          }}
        >
          <MenuItem value={49}>
            <Box
              borderRadius={2}
              fontFamily="Montserrat"
              fontWeight="700"
              display="flex"
              justifyContent="center"
              gap={0.5}
            >
              <img src={germany} alt="germany flag" />
              <Typography display="inline">+49</Typography>
            </Box>
          </MenuItem>
          <MenuItem value={41}>
            <Box
              borderRadius={2}
              fontFamily="Montserrat"
              fontWeight="700"
              display="flex"
              justifyContent="center"
              gap={0.5}
            >
              <img
                src={switzerland}
                alt="switzerland flag"
                width="32px"
                height="24px"
              />
              <Typography display="inline">+41</Typography>
            </Box>
          </MenuItem>
          <MenuItem value={43}>
            <Box
              borderRadius={2}
              fontFamily="Montserrat"
              fontWeight="700"
              display="flex"
              justifyContent="center"
              gap={0.5}
            >
              <img
                src={austria}
                alt="austria flag"
                width="32px"
                height="24px"
              />
              <Typography display="inline">+43</Typography>
            </Box>
          </MenuItem>
          <MenuItem value={352}>
            <Box
              borderRadius={2}
              fontFamily="Montserrat"
              fontWeight="700"
              display="flex"
              justifyContent="center"
              gap={0.5}
            >
              <img
                src={luxemburg}
                alt="luxemburg flag"
                width="32px"
                height="24px"
              />
              <Typography display="inline">+352</Typography>
            </Box>
          </MenuItem>
        </Select>
      </Box>

      <FormControl error variant="standard">
        <TextField
          error={
            (isValid === "true" && false) ||
            (isValid === "false" && true) ||
            (isValid === "" && false)
          }
          variant="outlined"
          id="phone-number"
          label="Telefon"
          placeholder="152546593"
          InputLabelProps={{ shrink: true }}
          color={isValid ? "success" : "primary"}
          value={number}
          onChange={numberChangeHandler}
        />

        <FormHelperText id="phone-number">
          {isValid === "false" && "Ungültige Nummer"}
        </FormHelperText>
      </FormControl>

      <CustomModal open={open} handleClose={handleClose} />
      <Box mt={8} mb={8}>
        <Button
          to="#"
          disabled={
            (isValid === "true" && false) ||
            (isValid === "false" && true) ||
            (isValid === "" && true)
          }
          onClick={handleOpen}
          component={Link}
          variant="contained"
        >
          WEITER
        </Button>
      </Box>
    </Box>
  );
}
