import React from 'react';

import { Box, Typography, Button, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedStageNumber } from '../../features/appData/appDataSlice';

//Assets
import Tick from '../../assets/greenTick.svg';

export default function StageCard({ title, body, stageNumber, solvedStages }) {
  const dispatch = useDispatch();

  const isCompleted = solvedStages.includes(stageNumber);

  function setQuestionHandler() {
    dispatch(setSelectedStageNumber(stageNumber));
  }

  return (
    <Box display='flex' justifyContent='center'>
      <Box
        width='324px'
        height='190px'
        minHeight='190px'
        mt={4}
        position='relative'
      >
        {!isCompleted && (
          <Link
            to='/select-people'
            onClick={setQuestionHandler}
            style={{
              textDecoration: 'none',
            }}
          >
            <CardBody
              isCompleted={isCompleted}
              title={title}
              body={body}
              setQuestionHandler={setQuestionHandler}
            />
          </Link>
        )}

        {isCompleted && (
          <CardBody
            isCompleted={isCompleted}
            title={title}
            body={body}
            setQuestionHandler={setQuestionHandler}
          />
        )}
      </Box>
    </Box>
  );
}

function CardBody({ isCompleted, title, body, setQuestionHandler }) {
  return (
    <Paper
      elevation={8}
      style={{
        borderRadius: '4px',
        backgroundColor: '#383838',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
      }}
    >
      <Box display='inline' position='absolute' left={290} bottom={155}>
        {isCompleted ? <img src={Tick} alt='selected' /> : ''}
      </Box>
      <Box>
        <Typography>{title}</Typography>
      </Box>
      <Box>
        <Typography variant='subtitle1' style={{ fontSize: '14px' }}>
          {body}
        </Typography>
      </Box>
      {!isCompleted && (
        <Box>
          <Button
            to='/select-people'
            component={Link}
            onClick={setQuestionHandler}
            style={{
              color: isCompleted ? '#20D268' : '#BB86FC',
              textDecoration: 'none',
            }}
          >
            BEANTWORTEN
          </Button>
        </Box>
      )}

      {isCompleted && (
        <Box>
          <Typography
            style={{
              color: '#20D268',
              fontWeight: '600',
              letterSpacing: '1px',
              fontSize: '14px',
            }}
          >
            GELÖST
          </Typography>
        </Box>
      )}
    </Paper>
  );
}
