//Libraries
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, Rating as RatingCom } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Components
import {
  RatingModal,
  FeedbackModal,
  ThankYouModal,
} from '../../components/CustomModal/CustomModal';

//Utils
import { db } from '../../utils/firebaseConfig';
import {
  addRating,
  findRating,
  addReview,
  findReview,
  updateTimeStamp,
} from '../../utils/firestoreFunctions';

//Assets
import homepageLogo from '../../assets/homepage-logo.png';

export default function Rating() {

  // some react state mangagement for local data
  const [value, setValue] = React.useState(0);
  const [openRating, setOpenRating] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openThankYou, setOpenThankYou] = useState(false);
  const [rated, setRated] = useState('null');
  const [feedback, setFeedback] = useState('');



  const navigate = useNavigate();

  const userPhoneNumber = useSelector((state) => state.userData.phoneNumber);
  const currentUser = useSelector((state) => state.userData.currentUser);






  // useEffect is called everytime the component it appears in is rendered
  // in this case it means every time the rating.jsx is called (the return at the bottom of the code)
  // this effect is called. It checks if there is already a review in firestore
  // if yes then the user is redirected to the start page and can't review again

  useEffect(() => {
    async function getData() {
      const result = await findRating(db, userPhoneNumber);
      if (result) {
        setRated(result);
        setValue(result.rating);
      }
    }
    getData();
  }, [userPhoneNumber]);


  useEffect(() => {
    if (rated !== 'null') {
      navigate('/');
    }
  }, [value]);






  // submitRating and close handles for the rating modal

  const handleSubmitRating = () => {
    addRating(db, parseInt(userPhoneNumber), value);
    setRated(true);
    if (value > 3) {
      setOpenRating(true);
    }
    //check if value is 3 or lower -> then we want to get a review by the user
    if (value > 0 && value < 4) {
      handleOpenFeedback(); // open the feedback modal
    }
  };

  const handleCloseRating = () => {
    setOpenRating(false);
    updateTimeClickedAmazonLink(); //logs clicks on amzon link
    navigate('/');
  };

  // for updating timeStamps collection -> phoneNumber document -> clickedAmazonLink timestamp
  const updateTimeClickedAmazonLink = async () => {
    let key = `clickedAmazonLink`;
    let obj = {};
    obj[key] = Math.floor(Date.now() / 1000);
  
    await updateTimeStamp(db, String(currentUser.phoneNumber), obj);
  };





  // Open and close handles for the feedback modal

  const handleOpenFeedback = () => {
    setOpenFeedback(true);
  };

  const handleCloseFeedback = () => {
    addReview(db, parseInt(userPhoneNumber), feedback);
    setOpenFeedback(false);
    handleOpenThankYou(); // open the thank you modal
    //navigate('/');
  };






  // Open and close handles for the ThankYou modal

  const handleOpenThankYou = () => {
    setOpenThankYou(true);
  };

  const handleCloseThankYou = () => {
    setOpenThankYou(false);
    navigate('/');
  };







  return (
    <Box textAlign='center' mt={2} color='white'>
      <img src={homepageLogo} alt='crime cases' width='180px' />
      <Box mt={12} px={8}>
        <Typography variant='h2'>
          {value >= 1
            ? 'WIE HAT EUCH DAS SPIEL GEFALLEN?'
            : 'WIE GUT HAT EUCH DAS SPIEL GEFALLEN?'}
        </Typography>
      </Box>
      <Box mt={12}>
        <RatingCom
          name='rating'
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          style={{ color: '#B683F6' }}
          size='large'
        />
      </Box>

      <RatingModal open={openRating} handleClose={handleCloseRating} />

      <FeedbackModal
        open={openFeedback}
        handleClose={handleCloseFeedback}
        feedback={feedback}
        setFeedback={setFeedback}
      />

      <ThankYouModal
        open={openThankYou}
        handleClose={handleCloseThankYou}
      />

      <Box mt={24} mb={8}>
        <Button
          variant='contained'
          onClick={rated ? handleSubmitRating : () => {}}
          disabled={value >= 1 && userPhoneNumber !== 'null' ? false : true}
        >
          WEITER
        </Button>
      </Box>
    </Box>
  );
}
