import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

import appDataSlice from '../features/appData/appDataSlice';
import userDataSlice from '../features/userData/userDataSlice';

const rootReducer = combineReducers({
  appData: appDataSlice,
  userData: userDataSlice,
});

const persistConfig = {
  key: 'persist',
  storage: storage,
  stateReconciler: hardSet,
};
const pReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: pReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
const persistor = persistStore(store);

export { persistor, store };
