//Libraries
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

//Components
import Homepage from '../Homepage/Homepage';
import SelectDifficulty from '../SelectDifficulty/SelectDifficulty';
import PhoneNumber from '../PhoneNumber/PhoneNumber';
import IntroductionAudio from '../IntroductionAudio/IntroductionAudio';
import Login from '../Login/Login';
import SelectStage from '../SelectStage/SelectStage';
import SelectPerson from '../SelectPerson/SelectPerson';
import StagesComplete from '../StagesComplete/StagesComplete';
import Rating from '../Rating/Rating';

//Utils
import MaterialTheme from '../../utils/MaterialTheme';

// START OF MATERIAL THEME

const theme = createTheme(MaterialTheme);

// END OF MATERIAL THEME

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/select-difficulty' element={<SelectDifficulty />} />
          <Route path='/phone-number' element={<PhoneNumber />} />
          <Route path='/introduction-audio' element={<IntroductionAudio />} />
          <Route path='/login' element={<Login />} />
          <Route path='/select-question' element={<SelectStage />} />
          <Route path='/select-people' element={<SelectPerson />} />
          <Route path='/stages-complete' element={<StagesComplete />} />
          <Route path='/rating' element={<Rating />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
