//Libraries
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Typography, Box } from '@mui/material';

//Components
import DifficultyCard from '../../components/Cards/DifficultyCard';

//Assets
import Difficulty2 from '../../assets/difficulty2.png';
import Difficulty1 from '../../assets/difficulty1.png';
import Difficulty3 from '../../assets/difficulty3.png';

//Utils
import { setDifficultyLevel } from '../../features/appData/appDataSlice';
import { db } from '../../utils/firebaseConfig';
import { updateDocData } from '../../utils/firestoreFunctions';

const LEVELS = ['easy', 'medium', 'hard'];
const DATA = [
  {
    text: 'EINSTEIGER',
    body: 'Keine Panik, der Kommissar hilft an den schwierigen Stellen weiter',
    difficultyImage: Difficulty1,
  },
  {
    text: 'MITTEL',
    body: 'Du bekommst zwar Tipps aber ein paar schwierige Rätsel musst du selbst lösen',
    difficultyImage: Difficulty2,
  },
  {
    text: 'PROFI',
    body: 'Für erfahrene Ermittler. Es gibt hier ein paar wirklich harte Nüsse!',
    difficultyImage: Difficulty3,
  },
];

export default function SelectDifficulty() {
  const selectedDifficultyLevel = useSelector(
    (state) => state.appData.selectedDifficultyLevel
  );

  const phoneNumber = useSelector((state) => state.userData.phoneNumber);
  const id = useSelector((state) => state.userData.currentUser.id);
  const dispatch = useDispatch();

  async function handler(e) {
    dispatch(setDifficultyLevel(e.target.value));
    if (phoneNumber !== 'null') {
      await updateDocData(db, id, {
        difficultySelected: selectedDifficultyLevel,
      });
    }
  }

  return (
    <Box textAlign='center' pt={8}>
      <Typography variant='h1'>WÄHLE DIE SCHWIERIGKEIT</Typography>
      <Box display='flex' justifyContent='center'>
        <Box display='flex' gap={4} my={8} overflow='auto'>
          {LEVELS.map((cardDifficultyLevel, i) => (
            <DifficultyCard
              handler={handler}
              cardDifficultyLevel={cardDifficultyLevel}
              text={DATA[i].text}
              body={DATA[i].body}
              difficultyImage={DATA[i].difficultyImage}
              selectedDifficultyLevel={selectedDifficultyLevel}
              key={i}
            />
          ))}
        </Box>
      </Box>
      <Box mb={8}>
        <Button
          variant='contained'
          disabled={
            selectedDifficultyLevel === 'easy' ||
            selectedDifficultyLevel === 'medium' ||
            selectedDifficultyLevel === 'hard'
              ? false
              : true
          }
          component={Link}
          to={phoneNumber === 'null' ? '/phone-number' : '/introduction-audio'}
        >
          WEITER
        </Button>
      </Box>
    </Box>
  );
}
