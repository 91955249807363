const MaterialTheme = {
  typography: {
    fontFamily: 'Roboto, Arial, Montserrat',
    button: {
      textDecoration: 'uppercase',
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '1.25px',
      fontStyle: 'normal',
    },
    h1: {
      textDecoration: 'uppercase',
      fontSize: '24px',
      letterSpacing: '0.4px',
      textAlign: 'center',
      color: 'white',
      opacity: '0.87',
      lineHeight: '30px',
    },
    h2: {
      textDecoration: 'uppercase',
      fontSize: '20px',
      letterSpacing: '0.4px',
      textAlign: 'center',
      color: 'white',
      opacity: '0.87',
      lineHeight: '20px',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      textAlign: 'center',
      textDecoration: 'uppercase',
      color: 'white',
      opacity: '87%',
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      textAlign: 'center',
      color: 'white',
      opacity: '50%',
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      textAlign: 'center',
      color: 'white',
      opacity: '60%',
    },
  },
  palette: {
    primary: {
      main: '#BB86FC',
    },
    secondary: {
      main: '#01A299',
    },
    mode: 'dark',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '10px 15px',
          fontWeight: 'bold',
        },
      },
    },
  },
};

export default MaterialTheme;
