//Libraries
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Typography,
  TextField,
  Box,
  Modal,
} from "@mui/material";
import { useSelector } from 'react-redux';


//Components

const style = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 290,
  bgcolor: '#383838',
  boxShadow: 2,
  p: 2,
  px: 4,
};



// the modal for starting the game with info to not have phone in airplane mode
export default function CustomModal({ open, handleClose }) {
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} textAlign='center'>
          <Box mb={2}>
            <Typography variant='subtitle1' color='#1DE555'>
              HINWEIS
            </Typography>
          </Box>
          <Typography variant='body2'>
            Bitte lasst das Handy während des Spiels in eurer Nähe und achtet
            darauf, dass es nicht stumm geschalten ist oder sich im Flugmodus
            befindet.
          </Typography>
          <Box mt={2}>
            <Button
              to='/introduction-audio'
              component={Link}
              onClick={handleClose}
            >
              Weiter
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}














// this is for giving the player feedback on his solution for the stage
export function ReplyModal({
  open,
  handleClose,
  handleCloseReplyModalShowHint,
  currentStageData,
  isCorrectAnswer,
  shouldShowHintButton,
  shouldShowSolutionButton,
}) {
  const currentUser = useSelector((state) => state.userData.currentUser);

  let link = '';

  if (!isCorrectAnswer) {
    link = '/select-people';
  } else if (currentUser.completedGame) {
    link = '/stages-complete';
  } else {
    link = '/select-question';
  }


  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} textAlign='center'>
          <Box mb={2}>
            <Typography
              variant='subtitle1'
              color={isCorrectAnswer ? '#1DE555' : '#E51D1D'}
            >
              {isCorrectAnswer ? 'RICHTIG' : 'DANEBEN'}
            </Typography>
          </Box>
          <Typography variant='body2'>


            {isCorrectAnswer === true
              ? currentStageData.correctReplyText
              : currentStageData.wrongReplyText}
          </Typography>
          
          

          {/* the block below does the following:
          case 1: answer is correct -> only show button to get back to selectStage page
          case 2: the answer is wrong but shouldShowHintButton is not true and shouldShowSolutionButton is not true -> show button to go back to people selection
          case 3: the answer is wrong but shouldShowHintButton is true (ie many wrong trials) -> show button to get hint but no back button
          case 4: the answer is wrong, but shouldShowSolutionButton is true (last hint) -> show solution and back button
          used syntax for that: {} for inputting js code to html
          if clause: (logical statement) ? doIfTrue() : doIfFalse() */}
          <Box mt={2}>


            { (isCorrectAnswer) ? <Button
              to={link}
              component={Link}
              onClick={handleClose}
              style={{ color: '#BB86FC' }}
            >
              Weiter
              </Button> : null
            }


            { (!isCorrectAnswer && !shouldShowHintButton && !shouldShowSolutionButton) ? <Button
                onClick={handleClose}
                style={{ color: '#BB86FC' }}
              >
              Zurück
              </Button> : null
            }


            { (!isCorrectAnswer && shouldShowHintButton) ? <Button
                onClick={handleCloseReplyModalShowHint}
                style={{ color: '#BB86FC' }}
              >
              Hinweis anzeigen
              </Button> : null
            }



            { (!isCorrectAnswer && shouldShowSolutionButton) ? <div>
              <Button
                onClick={handleCloseReplyModalShowHint}
                style={{ color: '#BB86FC' }}
              >
              Lösung anzeigen
              </Button>
              <Button
                onClick={handleClose}
                style={{ color: '#BB86FC' }}
              >
              Zurück
              </Button>
              </div> : null
            }





          </Box>



        </Box>
      </Modal>
    </div>
  );
}











// this is for giving hints when failing too often
export function HintModal({
  open,
  handleClose,
  hintText,
  hintModalTitleText,
}) {

  let link = '/select-people'

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} textAlign='center'>

          <Box mb={2}>
            <Typography variant='subtitle1'>
            {hintModalTitleText}
            </Typography>
          </Box>

          <Typography variant='body2'>
            {hintText}
          </Typography>
          
          <Box mt={2}>
            <Button
              to={link}
              component={Link}
              onClick={handleClose}
              style={{ color: '#BB86FC' }}
            >
            Weiter
            </Button>
          </Box>


        </Box>
      </Modal>
    </div>
  );
}














// this is for getting a rating (1-5 stars) of the game by the user
export function RatingModal({ open, handleClose }) {
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} textAlign='center'>
          <Box mb={2}>
            <Typography variant='subtitle1' color='#1DE555'> {/*green color*/}
              VIELEN DANK!
            </Typography>
          </Box>
          <Typography variant='body2'>
            Wenn ihr möchtet könnt ihr die Bewertung auch auf Amazon posten, das
            würde uns sehr helfen. Falls nicht könnt ihr die Seite einfach
            schließen
          </Typography>
          <Box mt={2}>
            <a href='https://www.amazon.de/reviews/edit-review/edit?ie=UTF8&asin=B09LYQWS73'>
              <Button onClick={handleClose}>Zu Amazon</Button>
            </a>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}




// this is for getting a review (text) of the game by the user
export function FeedbackModal({ open, handleClose, feedback, setFeedback }) {
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} textAlign='center'>
          <Box mb={2}>
            <Typography variant='subtitle1' color='#BB86FC'> {/*purple color*/}
              JETZT SIND WIR NEUGIERIG
            </Typography>
          </Box>
          <Typography variant='body2'>
            Willst du uns etwas wissen lassen?
          </Typography>
          <Box mt={4}>
            <TextField
              style={{ width: '100%' }}
              variant='outlined'
              id='feedback'
              label='Dein Feedback'
              InputLabelProps={{ shrink: true }}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              multiline
              rows={3}
            />
          </Box>
          <Box mt={2}>
            <Button onClick={handleClose}>Speichern</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}






// this is for thanking the user after giving 
export function ThankYouModal({ open, handleClose }) {
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} textAlign='center'>
          <Box mb={2}>
            <Typography variant='subtitle1' color='#1DE555'> {/*green color*/}
              VIELEN DANK!
            </Typography>
          </Box>
          <Typography variant='body2'>
            Wir werden versuchen dein Feedback umzusetzen.
          </Typography>
          <Box mt={2}>
            <Button onClick={handleClose}>Spiel beenden</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}