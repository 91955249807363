//Libraries
import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';

//Components
import Impressum from '../../components/Impressum/Impressum';

//Utils
import { updateTimeStamp } from '../../utils/firestoreFunctions';
import { db } from '../../utils/firebaseConfig';


//Assets
import Cover from '../../assets/policecover.svg';







export default function IntroductionAudio() {

  const [buttonState, setButtonState] = useState(false);
  const selectedDifficultyLevel = useSelector(
    (state) => state.appData.selectedDifficultyLevel
  );
  const currentUser = useSelector((state) => state.userData.currentUser);

  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser === 'null') {
      navigate('/');
    }
  }, [currentUser]);


  function audioComplete() {
    setButtonState(true);
    updateTimeFinishedAudioFile();
  }


  const updateTimeFinishedAudioFile = async () => {
    let key = `finishedFinalAudio`;
    let obj = {};
    obj[key] = Math.floor(Date.now() / 1000);
  
    await updateTimeStamp(db, String(currentUser.phoneNumber), obj);
  };
  

  return (
    <Box textAlign='center' pt={2}>
      <img src={Cover} alt='police Cover' />
      <Box mt={8}>
        <Typography variant='h2' px={8}>
          HÖRE DIR NUN DAS ABSCHLUSS AUDIO AN:
        </Typography>
      </Box>
      <Box mt={12} mb={8}>
        {selectedDifficultyLevel !== 'null' && (
          <ReactAudioPlayer
            src={`/audios/stages-complete.mp3`}
            controls
            controlsList='nodownload'
            onEnded={audioComplete}
          />
        )}
      </Box>

      <Box mt={20} mb={buttonState ? 16 : 40}>
        {buttonState && (
          <Button to='/rating' variant='contained' component={Link}>
            Weiter
          </Button>
        )}
      </Box>
      <Impressum />
    </Box>
  );
}
