import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  phoneNumber: 'null',
  currentUser: 'null',
};

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
});

export const { setPhoneNumber, setCurrentUser } = userDataSlice.actions;

export default userDataSlice.reducer;
