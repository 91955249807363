import React from 'react';
import { Box, Typography } from '@mui/material';

export default function Impressum() {
  return (
    <Box mt={8} mb={4}>
      <Typography>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://link.crime-cases.de/impressum'
          style={{
            fontFamily: 'Montserrat',
            lineHeight: '19.5px',
            fontSize: '16px',
            fontWeight: '400',
            color: 'white',
            opacity: '50%',
            letterSpacing: '2px',
            textDecoration: 'none',
          }}
        >
          Impressum
        </a>
      </Typography>
    </Box>
  );
}
