import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';
//import { getAnalytics } from "firebase/analytics"; // experimental!


//This is Production Database:

const firebaseConfig = {
  apiKey: 'AIzaSyD-Iz1qBdbj1XOsLmlHLitYedJ9Qycp1SE',
  authDomain: 'cc-01-kunstfehler-de.firebaseapp.com',
  projectId: 'cc-01-kunstfehler-de',
  storageBucket: 'cc-01-kunstfehler-de.appspot.com',
  messagingSenderId: '82880973420',
  appId: '1:82880973420:web:183705818582052374a649',
  measurementId: 'G-Z8B0VVDREH',
};


/*
This is testing database:

const firebaseConfig = {
  apiKey: "AIzaSyBLwv0PklJ2C1-asmrKZTZJpo-AHsD0z1c",
  authDomain: "tech-dev-demo.firebaseapp.com",
  projectId: "tech-dev-demo",
  storageBucket: "tech-dev-demo.appspot.com",
  messagingSenderId: "784811979864",
  appId: "1:784811979864:web:034b08af5b74af2305062d",
  measurementId: "G-KRC2DRTCRZ"
};
 */

//const analytics = getAnalytics(app); // experimental!


const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
