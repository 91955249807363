//Libraries
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { collection } from 'firebase/firestore';

//Components
import StageCard from '../../components/Cards/StageCard';
import Impressum from '../../components/Impressum/Impressum';

//Assets
import Cover from '../../assets/policecover.svg';

import {
  getStagesData,
  getUserDataWithPhoneNumber,
} from '../../utils/firestoreFunctions';
import { db } from '../../utils/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { setSelectedPersonsList } from '../../features/appData/appDataSlice';
import { setCurrentUser } from '../../features/userData/userDataSlice';

function areEqual(array1, array2) {
  if (array1.length === array2.length) {
    return array1.every((element, index) => {
      if (element === array2[index]) {
        return true;
      }

      return false;
    });
  }

  return false;
}

export default function SelectStage() {
  const [stagesData, setStagesData] = useState([]);
  const [isLoadingStages, setIsLoadingStages] = useState(true);

  const currentUser = useSelector((state) => state.userData.currentUser);
  const phoneNumber = useSelector((state) => state.userData.phoneNumber);

  const dispatch = useDispatch();

  //clearing state of selected person list
  useEffect(() => {
    dispatch(setSelectedPersonsList('flush'));
  }, []);

  const selectedDifficultyLevel = useSelector(
    (state) => state.appData.selectedDifficultyLevel
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (selectedDifficultyLevel === 'null') {
      navigate('/select-difficulty');
    }
    if (currentUser === 'null') {
      navigate('/login');
    }
    if (phoneNumber === 'null') {
      navigate('/login');
    }
  }, [selectedDifficultyLevel, currentUser, phoneNumber]);

  useEffect(() => {
    if (currentUser.completedGame) {
      navigate('/stages-complete', { replace: true });
    }
  }, [currentUser.completedGame]);

  useEffect(() => {
    (async () => {
      const stagesDataCollectionRef = collection(db, 'stagesData');
      setIsLoadingStages(true);
      let temp = await getStagesData(stagesDataCollectionRef);
      let userData = await getUserDataWithPhoneNumber(
        db,
        currentUser.phoneNumber
      );

      if (!areEqual(currentUser.solvedStages, userData.solvedStages)) {
        dispatch(setCurrentUser(userData));
      }
      setStagesData(temp);
      setIsLoadingStages(false);
    })();
  }, [currentUser.solvedStages]);

  return !isLoadingStages ? (
    <Box textAlign='center' pt={4}>

      <img src={Cover} alt='Polizei Gretenburg Logo'></img>

      <Box>
        {stagesData.map((stage, i) => (
          <StageCard
            key={i}
            title={`FRAGE ${stage.stageNumber}`}
            body={stage.fullQuestionText}
            stageNumber={stage.stageNumber}
            solvedStages={currentUser.solvedStages}
          />
        ))}
      </Box>


      

      <Box mb={8}>
        <Impressum />
      </Box>

    </Box>
  ) : (
    <Box mt={8} display='flex' justifyContent='center'>
      <CircularProgress />
    </Box>
  );
}
