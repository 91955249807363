//Libraries
import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useSelector, useDispatch } from 'react-redux';
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  updateDoc,
  query,
  getDocs,
  where,
  limit,
  connectFirestoreEmulator
} from 'firebase/firestore';

//Components
import PersonCard from '../../components/Cards/PersonCard';
import {
  ReplyModal,
  HintModal,
} from '../../components/CustomModal/CustomModal';
import CustomReward from '../../components/CustomReward/CustomReward';

//Utils
import { setDifficultyLevel, setSelectedPersonsList } from '../../features/appData/appDataSlice';
import {
  getData,
  updateDocData,
  updateTimeStamp,
  addWrongAnswerEntry,
} from '../../utils/firestoreFunctions';
import { db } from '../../utils/firebaseConfig';
import { setCurrentUser } from '../../features/userData/userDataSlice';
import { Link, useNavigate } from 'react-router-dom';
import { combineReducers } from 'redux';

export default function SelectPerson() {

  // state managment for persons and stages
  const [currentStageData, setCurrentStageData] = useState(null);
  const [personsToBeDisplayed, setPersonsToBeDisplayed] = useState([]);
  const [checkSolutionButtonisDisabled, setCheckSolutionButtonIsDisabled] = useState(true);
  const [isLoadingStagesData, setIsLoadingStagesData] = useState(true);
  const [isLoadingPersons, setIsLoadingPersons] = useState(true);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);

  // states for Hint Modal
  const [openReplyModal, setOpenReplyModal] = useState(false);
  const [openHintModal, setOpenHintModal] = useState(false);
  const [shouldShowHintButton, setShouldShowHintButton] = useState(false);
  const [hintText, setHintText] = useState(false);
  const [hintModalTitleText, setHintModalTitleText] = useState(false);
  const [shouldShowSolutionButton, setShouldShowSolutionButton] = useState(false);


  const selectedPersonsList = useSelector(
    (state) => state.appData.selectedPersonsList
  );
  const selectedStageNumber = useSelector(
    (state) => state.appData.selectedStageNumber
  );
  const currentUser = useSelector((state) => state.userData.currentUser);

  const selectedDifficultyLevel = useSelector(
    (state) => state.appData.selectedDifficultyLevel
  );

  const navigate = useNavigate();





  // upon render of the selectPerson page check if user has not logged in yet -> redirect to start
  useEffect(() => {
    if (selectedDifficultyLevel === 'null' || currentUser === 'null') {
      navigate('/', { replace: true });
    }
  }, [selectedDifficultyLevel]);

  const dispatch = useDispatch();





  // upon render of the selectPerson page load the to be displayed persons
  useEffect(() => {
    const get = async () => {
      setIsLoadingPersons(true);
      const personsCollectionRef = collection(db, 'persons');
      let temp = await getData(personsCollectionRef);
      setPersonsToBeDisplayed(temp);
      setIsLoadingPersons(false);
    };

    get();
  }, []);





  // upon render of the selectPerson page load the stagesData
  useEffect(() => {
    const get = async () => {
      const stagesDataCollectionRef = collection(db, 'stagesData');
      setIsLoadingStagesData(true);
      let temp = await getData(stagesDataCollectionRef);

      const currentTemp = temp.find(
        (i) => i.stageNumber === selectedStageNumber
      );
      setCurrentStageData(currentTemp);
      setIsLoadingStagesData(false);
    };
    get();
  }, [selectedStageNumber]);






  // upon render of the selectPerson page (ie on load or when person has been selected)
  // check if the user has made a proper selection of persons
  // if so, make the check solution button clickable
  useEffect(() => {
    if (currentStageData) {

      if (currentStageData.isSingle) {
        if (selectedPersonsList.selectedPersonName !== '') {
          setCheckSolutionButtonIsDisabled(false);
        } else {
          setCheckSolutionButtonIsDisabled(true);
        }

      } else {
        const keys = Object.keys(selectedPersonsList);
        let options = keys.map((i) => selectedPersonsList[i]);
        let temp = options.find((i) => i === '');
        if (temp === '') {
          setCheckSolutionButtonIsDisabled(true); // enable button
        } else {
          setCheckSolutionButtonIsDisabled(false);
        }
      }
    }
  }, [selectedPersonsList, currentStageData]);






  // update timeStamps collection -> phoneNumber document -> failedStageX entry with timestamp
  const updateWrongAnswerTime = async () => {
    let key = `failedStage${selectedStageNumber}`;
    let obj = {};
    obj[key] = Math.floor(Date.now() / 1000);

    await updateTimeStamp(db, String(currentUser.phoneNumber), obj);
  };




  // update wrongAnswers collection -> phoneNumber document -> stageX map -> timestamp array -> entries wrongAnswer1, wrongAnswer2
  const addWrongAnswerToMap = async () => {

    let unixTime = Math.floor(Date.now() / 1000)

    var usersUpdateData = {};
    var entryToBeMade = ''

    if (currentStageData.isSingle) {
      entryToBeMade = selectedPersonsList.selectedPersonName;
    }
    else {
      entryToBeMade = [selectedPersonsList[currentStageData.partialQuestions[0].partialQuestionNumber], selectedPersonsList[currentStageData.partialQuestions[1].partialQuestionNumber]]
    }

    usersUpdateData[`stage${selectedStageNumber}.${unixTime}`] = entryToBeMade;
    // If you would want to add the wrong answers directly into the userData Collection:
    // But putting it in different collection is more clean and more horizontal/denormalized which is nice in NoSQL
    //usersUpdate[`wrongAnswers.stage${selectedStageNumber}.${unixTime}`] = entryToBeMade;

    await addWrongAnswerEntry(db, String(currentUser.phoneNumber), usersUpdateData);
  };








  // update userData collection -> phoneNumber document -> add stagesCompleted array entry
  const updateStagesCompleted = async () => {
    const solvedStages = [...currentUser.solvedStages, selectedStageNumber];
    const isGameCompleted = solvedStages.length >= 4 ? true : false;

    await updateDocData(db, currentUser.id, {
      solvedStages: arrayUnion(selectedStageNumber),
      completedGame: isGameCompleted,
    });

    let key = `solvedStage${selectedStageNumber}`;
    let obj = {};
    obj[key] = Math.floor(Date.now() / 1000);

    await updateTimeStamp(db, String(currentUser.phoneNumber), obj);

    if (isGameCompleted) {
      await updateTimeStamp(db, String(currentUser.phoneNumber), {
        completedGame: Math.floor(Date.now() / 1000),
      });
    }
    // update local state data:
    dispatch(
      setCurrentUser({
        ...currentUser,
        solvedStages,
        completedGame: isGameCompleted,
      })
    );
  };








  const setHintSolutionStates = async () => {

    // [easy_array, medium_array, hard_array]
    // hint 1, hint 2, ... , solution hint
    // that means the solution is just a special hint (special headline on hint modal and special button)
    let currentStageErrorsThresholds = [ [1,2,3,4], [2,3,4,5], [2,4,6,8] ];


    // get numberOfCurrentStageFails from firestore wrongAnswers -> stageX -> entries
    const wrongAnswerDocRef = doc(db, `wrongAnswers/${currentUser.phoneNumber}`);
    let wrongAnswerDocSnap = await getDoc(wrongAnswerDocRef);
    let wrongAnswersData = wrongAnswerDocSnap.data();

    let wrongAnswersCurrentStage = wrongAnswersData[`stage${selectedStageNumber}`];
    // catch case of no wrong entries yet -> not entry in wrongAnswers
    let numberOfCurrentStageFails = 0;
    if (wrongAnswersCurrentStage != undefined){
      numberOfCurrentStageFails = Object.keys(wrongAnswersData[`stage${selectedStageNumber}`]).length;
    }
    else{
      numberOfCurrentStageFails = 0;
    }
    



    // get numberOfCurrentStageDisplayedHints from firestore userData collection -> phoneNumber document -> displayedWrongAnswerHints map -> stageX array -> entries
    const userDataDocRef = doc(db, `userData/${currentUser.phoneNumber}`);
    let userDataDocSnap = await getDoc(userDataDocRef);
    let userDataData = userDataDocSnap.data();
    let currentStageDisplayedHints = userDataData['displayedWrongAnswerHints'][`stage${selectedStageNumber}`];
    // catch case of no wrong entries yet -> not entry in stageX
    let numberOfCurrentStageDisplayedHints = 0;
    if (currentStageDisplayedHints != undefined){
      numberOfCurrentStageDisplayedHints = currentStageDisplayedHints.length;
    }
    else{
      numberOfCurrentStageDisplayedHints = 0;
    }


    // get numberOfCurrentStageDisplayedHints from firestore stagesData collection -> stage random id document -> hintTexts array -> entry number X
    const q = query(
      collection(db, 'stagesData'),
      where('stageNumber', '==', parseInt(selectedStageNumber)),
      limit(1)
    );
    const querySnapshot = await getDocs(q);
    let stagesDataData
    querySnapshot.forEach((doc) => {
      stagesDataData = { ...doc.data() };
    });




    let difficultyArrayPosition = 0;
    switch (selectedDifficultyLevel){
      case "easy":
        difficultyArrayPosition = 0;
        break;
      case "medium":
        difficultyArrayPosition = 1;
        break;
      case "hard":
        difficultyArrayPosition = 2;
        break;
    }

      // case 1: hint should be displayed. Conditions for this are:
        // condition 1: for the given stage the minimum wrong tries threshold has to be passed
        // condition 2: for the given stage there still must be some hints be left to give
        // condition 3: for the given stage the number of fails must be bigger than the threshold for the next hint which is to be given
        // all have to be met (AND)
      // case 2 (elseif): solution hint should be shown
        // condition 1: we have more failed entries than the highest specified error threshold
        // condition 2: we have not given more hints (including solution) that there is hints in total (including solution)
        // both have to be met (AND)


    let currentHintText = stagesDataData['hintTexts'][numberOfCurrentStageDisplayedHints];
    setHintText(currentHintText);

    let hintTresholds = currentStageErrorsThresholds[difficultyArrayPosition].slice(0, -1); // .slice(0,-1) gives whole array exept last entry,
    let solutionThreshold = currentStageErrorsThresholds[difficultyArrayPosition].slice(-1)[0]; // .slice(-1) gives only last entry (but as a 1 element array) -> [0] for value
    let numMaxHintsPlusSolution = currentStageErrorsThresholds[difficultyArrayPosition].length; 

    // ... unpacks arrays (like * in pyhton)
    if ( (numberOfCurrentStageFails >= Math.min( ... hintTresholds)) && (numberOfCurrentStageDisplayedHints < hintTresholds.length) && (numberOfCurrentStageFails >= hintTresholds[numberOfCurrentStageDisplayedHints]) ) {
      setShouldShowHintButton(true);
      setShouldShowSolutionButton(false);
      setHintModalTitleText("HINWEIS " + String(numberOfCurrentStageDisplayedHints + 1) );
    }
    else if ( (numberOfCurrentStageFails >= solutionThreshold) && (numberOfCurrentStageDisplayedHints < numMaxHintsPlusSolution) ) {
      setShouldShowHintButton(false);
      setShouldShowSolutionButton(true);
      setHintModalTitleText("LÖSUNG");

    }
    else {
      setShouldShowHintButton(false);
      setShouldShowSolutionButton(false);
    }

      

  }






  // open and close handles for the reply modal giving the user feedback on his solution

  const handleOpenReplyModal = async () => {
    
    if (currentStageData.isSingle) {
      if (
        currentStageData.correctAnswer ===
        selectedPersonsList.selectedPersonName
      ) {
        // SEND REQUEST
        await updateStagesCompleted();
        setIsCorrectAnswer(true);
      } else {
        updateWrongAnswerTime();
        addWrongAnswerToMap();
        setIsCorrectAnswer(false);
      }
    }

    if (!currentStageData.isSingle) {
      if (
        currentStageData.partialQuestions[0].correctAnswer ===
          selectedPersonsList[
            currentStageData.partialQuestions[0].partialQuestionNumber
          ] &&
        currentStageData.partialQuestions[1].correctAnswer ===
          selectedPersonsList[
            currentStageData.partialQuestions[1].partialQuestionNumber
          ]
      ) {
        // SEND REQUEST
        await updateStagesCompleted();
        setIsCorrectAnswer(true);
      } else {
        updateWrongAnswerTime();
        addWrongAnswerToMap();
        setIsCorrectAnswer(false);
      }
    }

    // set the state for the modal to show hint button or solution button and respective texts
    await setHintSolutionStates();

    //console.log(shouldShowHintButton)
    //console.log(shouldShowSolutionButton)
    
    setOpenReplyModal(true);
  };

  const handleCloseReplyModal = () => {
    setOpenReplyModal(false);
  }




  const handleCloseReplyModalShowHint = async () => {
    setOpenReplyModal(false);

    // update which hints have been displayed in
    // userData collection -> displayedWrongAnswerHints map -> stageX array -> timestamp
    let userDataDocRef = doc(db, `userData/${currentUser.phoneNumber}`);
    let key = `displayedWrongAnswerHints.stage${selectedStageNumber}`;
    let obj = {};
    obj[key] = arrayUnion( hintText );
    
    updateDoc(userDataDocRef, obj);
    
    setOpenHintModal(true);
  }


  const handleCloseHintModal = () => {
    setOpenHintModal(false);
  }


  // if user clicks on the help link displayed at bottom of people selection page
  const openHelpLink = () => {

    if (selectedStageNumber == '4'){
      window.open('https://hilfe.crime-cases.de/motive/');
    }
    else{
      window.open('https://hilfe.crime-cases.de/');
    }

  }






  return !isLoadingStagesData && !isLoadingPersons ? (
    <Box mt={4} alignItems='center' display='flex' flexDirection='column'>
      <PageTitle title={currentStageData.fullQuestionText} />
      {isCorrectAnswer && <CustomReward />}
      {currentStageData.isSingle && (
        <>
          <QuestionSection isSingle={true} persons={personsToBeDisplayed} />
        </>
      )}
      {currentStageData.isSingle !== true &&
        currentStageData.partialQuestions.map((question, i) => (
          <QuestionSection
            question={question}
            key={i}
            isSingle={currentStageData.isSingle}
            persons={personsToBeDisplayed}
          />
        ))}

      <ReplyModal
        open={openReplyModal}
        handleClose={handleCloseReplyModal}
        handleCloseReplyModalShowHint={handleCloseReplyModalShowHint}
        currentStageData={currentStageData}
        isCorrectAnswer={isCorrectAnswer}
        shouldShowHintButton={shouldShowHintButton}
        shouldShowSolutionButton={shouldShowSolutionButton}
      />
      <HintModal
        open={openHintModal}
        handleClose={handleCloseHintModal}
        hintText={hintText}
        hintModalTitleText={hintModalTitleText}
      />
      

      <Box mt={8}>
        <Button disabled={checkSolutionButtonisDisabled} variant='contained' onClick={handleOpenReplyModal}>
          LÖSUNG ÜBERPRÜFEN
        </Button>
      </Box>
      <Box mt={2} mb={8}>
        <Button to='/select-question' component={Link}>
          Abbrechen
        </Button>
      </Box>



      {(selectedStageNumber=='4') ? <Box mt={8} mb={3}>
        <Button variant='text' onClick={openHelpLink} style={{ color: '#2d6198' }}>
          Hilfe zu Frage 4
        </Button>
      </Box> : null}
      


    </Box>
  ) : (
    <Box mt={8} display='flex' justifyContent='center'>
      <CircularProgress />
    </Box>
  );
}

//Helper Components
function PageTitle({ title }) {
  return (
    <Box
      py={4}
      px={1}
      border='2px solid white'
      width='340px'
      borderRadius={1.5}
    >
      <Typography variant='h2'>{title}</Typography>
    </Box>
  );
}

function QuestionSection({ isSingle, question, persons }) {
  const [selectedPersonName, setSelectedPersonName] = useState('');
  const dispatch = useDispatch();

  function personNameHandler(e) {
    setSelectedPersonName(e.target.value);
  }

  useEffect(() => {
    dispatch(
      setSelectedPersonsList({
        partialQuestionNumber:
          isSingle === false ? question.partialQuestionNumber : '',
        selectedPersonName: selectedPersonName,
        isSingle: isSingle,
      })
    );
  }, [selectedPersonName, isSingle]);

  return (
    <Box mt={4} width='95%'>
      {isSingle === true ? (
        ''
      ) : (
        <Box mb={4} mt={4}>
          <Typography variant='h2'>{question.question}</Typography>
        </Box>
      )}
      <Grid container justifyContent='center'>
        {persons.map((p, i) => (
          <Grid item key={i} xs={6} md={3} lg={2}>
            <Box mb={4} display='flex' justifyContent='center'>
              <PersonCard
                name={p.name}
                selectedPersonName={selectedPersonName}
                personNameHandler={personNameHandler}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
