//Libraries
import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

//Components
import Button from '@mui/material/Button';
import Impressum from '../../components/Impressum/Impressum';

//Assets
import homepageLogo from '../../assets/homepage-logo.png';
import crimeCasesCover from '../../assets/crime-cases-cover.png';

export default function Homepage() {
  return (
    <Box textAlign='center' mt={2} color='white'>
      <img src={homepageLogo} alt='crime cases' width='180px' />
      {/* Inline styles because this is only used on this page */}
      <h2 style={{ fontFamily: 'Courier', opacity: '50%' }}>Akte 001</h2>
      <h1 style={{ fontFamily: 'Courier', fontWeight: '700' }}>Kunstfehler</h1>
      <Box mt={4}>
        <Link to='/select-difficulty'>
          <img
            src={crimeCasesCover}
            alt='crime cases cover'
            width='332px'
            height='271px'
            style={{ border: '1px solid white', boxSizing: 'border-box' }}
          />
        </Link>
      </Box>
      <Box mt={8} mb={8}>
        <Button variant='contained' to='select-difficulty' component={Link}>
          Spiel Starten
        </Button>
      </Box>
      <Impressum />
    </Box>
  );
}
